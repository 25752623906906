import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import noty from "./plugins/noty";
import numeral from "./plugins/numeral";
import htmlToPaper from "./plugins/htmltopaper";
import moment from "moment";
import "vuejs-noty/dist/vuejs-noty.css";
import JsonExcel from "vue-json-excel";
import VueExcelEditor from "./plugins/excelEditor";
import "./registerServiceWorker";
moment.locale("id");

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});
Vue.filter("hari", function (value) {
  if (value) {
    return moment(String(value)).format("dddd");
  }
});

Vue.component("downloadExcel", JsonExcel);
axios.defaults.baseURL = "https://core.smjakd.my.id/api";
// axios.defaults.baseURL = "http://localhost:8000/api";
// axios.defaults.baseURL = "http://smjpc/smjonline/public/api";

// online: https://smjakd.online/core/api, folder riz di public buat menjadi aplikasi/riz, buka config:base url ganti jadi /
// offline : 'http://smjpc/smj-pinjaman/public/api'

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  noty,
  numeral,
  htmlToPaper,
  VueExcelEditor,
  render: (h) => h(App),
}).$mount("#app");
